export const checkData = [
   {
      samplecode: "TGJ",
      stdcode: "GLQ01006",
      need: ["GLQ01005"],
      tips: "天然稠度+液塑限"
   },
   {
      samplecode: "TGJ",
      stdcode: "GLQ01008",
      need: ["GLQ01007"],
      tips: "天承载比+击实试验"
   },
   {
      samplecode: "TGJ",
      stdcode: "GLQ01008a",
      need: ["GLQ01007a"],
      tips: "天承载比+击实试验"
   },
   {
      samplecode: "CJL",
      stdcode: "GLQ02008",
      need: ["GLQ02001a"],
      tips: "坚固性+颗粒级配"
   },
   {
      samplecode: "CJL",
      stdcode: "GLQ02019",
      need: ["GLQ02013a"],
      tips: "坚固性+颗粒级配"
   },
   {
      samplecode: "XJL",
      stdcode: "GLQ02024",
      need: ["GLQ02002a"],
      tips: "棱角性（流动时间法）+表观密度"
   },
   {
      samplecode: "SNJ",
      stdcode: "GLQ04002b",
      need: ["GLQ04001"],
      tips: "比表面积+密度"
   },
   {
      samplecode: "SNJ",
      stdcode: "GLQ04002b",
      need: ["GLQ04001"],
      tips: "比表面积+密度"
   },
   {
      samplecode: "SNJ",
      stdcode: "GLQ04004a",
      need: ["GLQ04003"],
      tips: "比凝结时间+标准稠度"
   },

   {
      samplecode: "WJJ",
      stdcode: "GLQ007008a",
      need: ["GLQ07012"],
      tips: "凝结时间+含固量"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07006e",
      need: ["GLQ07012"],
      tips: "凝结时间+含固量"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07006d",
      need: ["GLQ07012"],
      tips: "28d抗压强度比+含固量"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ070015a",
      need: ["GLQ07014"],
      tips: "比表面积+密度"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07005",
      need: ["GLQ07004","GLQ07012"],
      tips: "泌水率比+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07009",
      need: ["GLQ07004","GLQ07012"],
      tips: "含气量+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07008",
      need: ["GLQ07004","GLQ07012"],
      tips: "凝结时间差+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07010a1h",
      need: ["GLQ07004","GLQ07012"],
      tips: "经时变化量坍落度+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07006i",
      need: ["GLQ07004","GLQ07012"],
      tips: "抗压强度比+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07007",
      need: ["GLQ07004","GLQ07012"],
      tips: "收缩率比+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07011",
      need: ["GLQ07004","GLQ07012"],
      tips: "相对耐久性+(减水率+含固量度)"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07009",
      need: ["GLQ07004"],
      tips: "含气量+减水率"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07008",
      need: ["GLQ07004"],
      tips: "凝结时间差+减水率"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07010a1h",
      need: ["GLQ07004"],
      tips: "经时变化量坍落度+减水率"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07006i",
      need: ["GLQ07004"],
      tips: "抗压强度比（3d、7d、28d）+减水率"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07007",
      need: ["GLQ07004"],
      tips: "收缩率比+减水率"
   },
   {
      samplecode: "WJJ",
      stdcode: "GLQ07011",
      need: ["GLQ07004"],
      tips: "相对耐久性+减水率"
   },
   {
      samplecode: "CHL",
      stdcode: "GLQ08012",
      need: ["GLQ08011"],
      tips: "氧化镁含量+氧化钙含量,EDTA滴定差减法（代用法）",
   },
   {
      samplecode: "CHL",
      stdcode: "GLQ08012",
      need: ["GLQ04003"],
      tips: "安定性+标准稠度用水量"
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10016",
      need: ["GLQ10017"],
      tips: "乳化沥青筛上剩余物+粒子电荷"
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10018",
      need: ["GLQ10017"],
      tips: "乳化沥青与粗集料的黏附性+粒子电荷"
   },
   {
      samplecode: "LQJ",
      stdcode: "GGLQ10020",
      need: ["GLQ10017","GLQ10015a"],
      tips: "与水泥拌和试验(筛上残留物含量）+粒子电荷 +蒸发残留物（残留分含量、溶解度、针入度15℃、延度15℃）"
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10002a",
      need: ["GLQ10015a"],
      tips: "针入度（25℃）+蒸发残留物（残留分含量、溶解度、针入度25℃、延度15℃）",
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10003a",
      need: ["GLQ10015a"],
      tips: "延度+蒸发残留物（残留分含量、溶解度、针入度25℃、延度15℃）",
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10003b",
      need: ["GLQ10015a"],
      tips: "延度(15℃)+蒸发残留物（残留分含量、溶解度、针入度25℃、延度15℃）",
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10003c",
      need: ["GLQ10015a"],
      tips: "延度(25℃)+蒸发残留物（残留分含量、溶解度、针入度25℃、延度15℃）",
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10003d",
      need: ["GLQ10015a"],
      tips: "延度(5℃)+蒸发残留物（残留分含量、溶解度、针入度25℃、延度15℃）",
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10004",
      need: ["GLQ10015a"],
      tips: "软化点+蒸发残留物（残留分含量、溶解度、针入度25℃、延度15℃）",
   },

   
   {
      samplecode: "LQJ",
      stdcode: "GLQ10005",
      need: ["GLQ10015a"],
      tips: "乳化沥青溶解度+蒸发残留物",
   },
   {
      samplecode: "LQJ",
      stdcode: "GLQ10021",
      need: ["GLQ10017"],
      tips: "乳化沥青破乳速度+粒子电荷",
   },

   {
      samplecode: "LQJ",
      stdcode: "GLQ10006",
      need: ["GLQ10002a"],
      tips: "薄膜加热试验+针入度"
   },
   {
      samplecode: "QPB",
      stdcode: "GLQ11001",
      need: ["GLQ02002b","GLQ02001"],
      tips: "配合比设计+表观相对密度(粗)、毛体积相对密度+颗粒级配(粗)"
   },
   {
      samplecode: "TFJ",
      stdcode: "GLQ12005",
      need: ["GLQ12004"],
      tips: "延伸率+拉伸强度"
   },
   {
      samplecode: "STC",
      stdcode: "SYQ12022",
      need: ["SYQ12024","SYQ12008"],
      tips: "抗紫外线性能+拉伸强度+伸长率"
   },
   {
      samplecode: "FSJ",
      stdcode: "GLQ14015a",
      need: ["SYQ12024","SYQ12008"],
      tips: "延伸率+拉伸强度"
   },
   {
      samplecode: "LSJ",
      stdcode: "GLP03002",
      need: ["GLP03001"],
      tips: "高强度螺栓连接副扭矩系数+高强度螺栓连接副紧固轴力（GB/T 3632)"
   },
   {
      samplecode: "LSJ",
      stdcode: "GLP03003",
      need: ["GLP03001"],
      tips: "高强度螺栓连接副抗滑移系数+高强度螺栓连接副紧固轴力（GB/T 3632)"
   },
   {
      samplecode: "LSJ",
      stdcode: "GLQ17007a",
      need: ["GLQ17006a"],
      tips: "抗剪老化(500mm以下)+抗剪弹性模量(500mm以下)"
   },
   {
      samplecode: "LSJ",
      stdcode: "GLQ17007b",
      need: ["GLQ17006b"],
      tips: "抗剪老化(500mm以上)+抗剪弹性模量(500mm以上)"
   },

   {
      samplecode: "FSJ",
      stdcode: "GLQ14041",
      need: ["GLQ14040"],
      tips: "单位面积质量+面积"
   },
   {
      samplecode: "MJJ",
      stdcode: "GLQ16006",
      need: ["GLQ16007", "GLQ16001"],
      tips: "静载锚固性能+硬度，最大力"
   },
   {
      samplecode: "MJJ",
      stdcode: "GLQ16008",
      need: ["GLQ16001"],
      tips: "松弛性能+最大力"
   },
   {
      samplecode: "ZZJ",
      stdcode: "GLQ17007",
      need: ["GLQ17005"],
      tips: "抗剪老化+老化前抗剪弹性模量"
   },
   {
      samplecode: "ZZJ",
      stdcode: "GLQ17007",
      need: ["GLQ17005"],
      tips: "抗剪老化+老化前抗剪弹性模量"
   },
   {
      samplecode: "GLS",
      stdcode: "GLQ23005",
      need: ["GLQ23013"],
      tips: "防腐层厚度+镀锌附着量(仅限于双涂层（镀锌+浸塑或聚酯等）)"
   },

]