import React, { useState, useRef } from "react";
import Portal from "@material-ui/core/Portal";
import { Link } from "react-router-dom";
import { css } from "emotion";
import "@reach/dialog/styles.css";
import { Dialog } from "@reach/dialog";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";

import {
  fetchSampleExcel,
  fetchRwExcel,
  fetchPriceExcel,
  fetchSamplePdf,
  fetchDeleteSample,
  fetchSampleStatusChange,
} from "../apidata/fetchSampleData";
import Doc from "./Doc";
import TaskDetail from "./TaskDetail";
import { useAlert } from "react-alert";

export default function MyPortal({ item, deleteUi, changeStatusUi }) {
  const [show, setShow] = useState(false);
  const container = useRef(null);
  const [msg, setMsg] = useState();
  const alert = useAlert();

  // 附件上传
  const [showDialogDoc, setShowDialogDoc] = useState(false);
  const openDoc = () => setShowDialogDoc(true);
  const closeDoc = () => setShowDialogDoc(false);

  // 册除样品单和委托单
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const cancelRef = useRef();
  const openDelete = () => setShowDialogDelete(true);
  const closeDelete = () => setShowDialogDelete(false);

  // 作废委托单
  const [showDialogCancel, setShowDialogCancel] = useState(false);
  const openCancel = () => setShowDialogCancel(true);
  const closeCancel = () => setShowDialogCancel(false);

  // 还原委托单
  const [showDialogRestore, setShowDialogRestore] = useState(false);
  const openRestore = () => setShowDialogRestore(true);
  const closeRestore = () => setShowDialogRestore(false);

  const [showDialogTask, setShowDialogTask] = useState(false);
  const openTask = () => setShowDialogTask(true);
  const closeTask = () => setShowDialogTask(false);

  const [exportRwLoading, setExportRwLoading] = useState(false);
  const [exportSampleExcelLoading, setExportSampleExcelLoading] = useState(
    false
  );
  const [exportSamplePdfLoading, setExportSamplePdfLoading] = useState(false);
  const [exportSamplePriceLoading, setExportSamplePriceLoading] = useState(
    false
  );

  const exportSampleExcel = async (item) => {
    setExportSampleExcelLoading(true);
    try {
      const res = await fetchSampleExcel(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `${item.trust.certificate === 0 ? "JHWT" : "JHSWT"}-${
        item.trust.year
      }-${item.trust.trustnumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportSampleExcelLoading(false);
  };

  const exportRwExcel = async () => {
    setExportRwLoading(true);
    try {
      const res = await fetchRwExcel(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `JHRW"-${item.trust.year}-${item.trust.tasknumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportRwLoading(false);
  };

  const exportPirceExcel = async () => {
    setExportSamplePriceLoading(true);
    try {
      const res = await fetchPriceExcel(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `价格清单${
        item.trust.certificate === 0 ? "JHRW" : "JHSRW"
      }-${item.trust.year}-${item.trust.trustnumber
        .toString()
        .padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportSamplePriceLoading(false);
  };

  const exportSamplePdf = async () => {
    setExportSamplePdfLoading(true);
    try {
      const res = await fetchSamplePdf(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `${item.trust.certificate === 0 ? "JHWT" : "JHSWT"}-${
        item.trust.year
      }-${item.trust.trustnumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
    setExportSamplePdfLoading(false);
  };

  const deleteSample = async () => {
    try {
      const json = await fetchDeleteSample(item.id);
      setMsg(json.msg);
      closeDelete();
      deleteUi(item.id);
    } catch (error) {
      setMsg(error.message);
      closeDelete();
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    }
  };
  // 作废委托单
  const cancelSample = async () => {
    // if (item.status>2){
    //   alert.error("已发放了任务,不能作废,先去撒消任务或找实验员退回");
    //   return;
    // }
    alert.info("已发放了任务,一定要找实验员撒消任务");
    try {
      const json = await fetchSampleStatusChange(item.id, -1);
      alert.success(`作废${json.msg}`);
      setMsg(`作废${json.msg}`);
      closeCancel();
      changeStatusUi(item.id, -1);
    } catch (error) {
      alert.error(error.message);
      setMsg(error.message);
      closeCancel();
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    }
  };

  // 恢复委托单
  const restore = async () => {
    try {
      const json = await fetchSampleStatusChange(item.id, 0);
      setMsg(`恢复${json.msg}`);
      closeRestore();
      changeStatusUi(item.id, 0);
    } catch (error) {
      setMsg(error.message);
      closeRestore();
      setTimeout(() => {
        setMsg(null);
      }, 6000);
    }
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          setShow(!show);
        }}
      >
        {show ? "收起" : "展开"}
      </button>
      {show ? (
        <Portal container={container.current}>
          <p>{msg}</p>

          <div>
            <button
              className={css`
                margin-bottom: 5px;
              `}
              disabled={exportSampleExcelLoading}
              onClick={() => {
                exportSampleExcel(item);
              }}
            >
              {exportSampleExcelLoading ? "导出中.." : "导出委托单Excel"}
            </button>

            <button
              className={css`
                margin-bottom: 5px;
              `}
              disabled={exportSamplePdfLoading}
              onClick={() => {
                exportSamplePdf();
              }}
            >
              {exportSamplePdfLoading ? "导出中.." : "导出委托单pdf"}
            </button>
          </div>

          <div>
            <button
              className={css`
                margin-bottom: 5px;
                display: block;
              `}
              disabled={exportSamplePriceLoading}
              onClick={() => {
                exportPirceExcel();
              }}
            >
              {exportSamplePriceLoading ? "导出中.." : "导出价格清单excel"}
            </button>
            <button
              className={css`
                margin-bottom: 5px;
              `}
              disabled={exportRwLoading}
              onClick={() => {
                exportRwExcel();
              }}
            >
              {exportRwLoading ? "导出中.." : "导出任务单"}
            </button>
            <button
              className={css`
                margin-bottom: 5px;
                display: block;
              `}
              onClick={() => {
                openDoc();
              }}
            >
              上传附件
            </button>
          </div>
          <div>
            <Link
              to={{
                pathname: `/sendreport/${item.id}`,
                data: item,
              }}
            >
              发放报告
            </Link>
          </div>
          <hr />

          <div>
            <button
              style={{ marginBottom: "10px" }}
              className="btn btn-danger"
              onClick={openDelete}
            >
              删除委托单(慎用)
            </button>
          </div>

          <div>
            <button
              style={{ marginBottom: "10px" }}
              className="btn btn-warning"
              onClick={openCancel}
            >
              作废委托单
            </button>
          </div>
          <div>
            <button
              style={{ marginBottom: "10px" }}
              className="btn btn-success"
              onClick={openRestore}
            >
              恢复委托单
            </button>
          </div>
          <div>
            <button style={{ marginBottom: "10px" }} onClick={openTask}>
              任务详情
            </button>
          </div>
          <div>
            <Link
              to={{
                pathname: `/sampleupdatesamplecode/${item.id}`,
                data: item,
              }}
            >
              仅修改样品单号
            </Link>
          </div>
        </Portal>
      ) : null}
      <div ref={container}></div>

      {showDialogDoc && (
        <Dialog aria-label="modal" isOpen={showDialogDoc} onDismiss={closeDoc}>
          <h1>附件上传</h1>
          <Doc sample={item} />
          <hr />
          <button onClick={closeDoc}>关闭</button>
        </Dialog>
      )}

      {showDialogDelete && (
        <AlertDialog onDismiss={closeDelete} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center", color: "red" }}>
            请再次确认是否删除?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>{`委托单号:${item.trust.certificate === 0 ? "JHWT" : "JHSWT"}-${
              item.trust.year
            }-${item.trust.trustnumber.toString().padStart(4, "0")}`}</p>
            <p>{`委托单位:${item.trust.trustname}`}</p>
            <p>{`工程名称:${item.trust.projectname}`}</p>
            <p>{`委托人:${item.trust.trustperson}`}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={deleteSample}>
              是, 删除
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeDelete}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}

      {showDialogCancel && (
        <AlertDialog onDismiss={closeCancel} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否作废?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>{`委托单号:${item.trust.certificate === 0 ? "JHWT" : "JHSWT"}-${
              item.trust.year
            }-${item.trust.trustnumber.toString().padStart(4, "0")}`}</p>
            <p>{`委托单位:${item.trust.trustname}`}</p>
            <p>{`工程名称:${item.trust.projectname}`}</p>
            <p>{`委托人:${item.trust.trustperson}`}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={cancelSample}>
              是, 作废
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeCancel}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}

      {showDialogRestore && (
        <AlertDialog onDismiss={closeRestore} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否还原委托单?
          </AlertDialogLabel>
          <AlertDialogDescription>
            <p>{`委托单号:${item.trust.certificate === 0 ? "JHWT" : "JHSWT"}-${
              item.trust.year
            }-${item.trust.trustnumber.toString().padStart(4, "0")}`}</p>
            <p>{`委托单位:${item.trust.trustname}`}</p>
            <p>{`工程名称:${item.trust.projectname}`}</p>
            <p>{`委托人:${item.trust.trustperson}`}</p>
          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-success" onClick={restore}>
              是, 还原
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeRestore}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}

      {showDialogTask && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogTask}
          onDismiss={closeTask}
        >
          <h1>任务信息</h1>
          <TaskDetail sample={item} />
          <hr />
          <button onClick={closeTask}>关闭</button>
        </Dialog>
      )}
    </div>
  );
}
