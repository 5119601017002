import baseURL from "../Config";



export const fetchSendTask = async data => {
  try {
    const res = await fetch(`${baseURL}/api/v1/wpsfile/sendtask`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
      body: JSON.stringify(data),
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchCancelTask = async id => {
  try {
    const res = await fetch(`${baseURL}/api/v1/wpsfile/canceltask/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      }
    });
    if (res.ok){
      const json = await res.json();
      return json;
    }else{
      throw new Error("这个单无需取消或者不你发放的单");
    }

  } catch (error) {
    throw new Error("这个单无需取消或者不你发放的单");
  }
};



export const fetchTaskCount = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/task/count`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      },
    });
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};

export const fetchTaskList = async (pageSize, currentPage) => {
  try {
    const res = await fetch(
      `${baseURL}/api/v1/task/list/${pageSize}/${currentPage}`,
      {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer " + JSON.parse(sessionStorage.getItem("token")),
        },
      }
    );
    const json = await res.json();
    return json;
  } catch (error) {
    throw new Error(error);
  }
};


export const fetchTask = async sample_id => {
  try {
    const res = await fetch(`${baseURL}/api/v1/task/querybysampleid/${sample_id}`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};



export const fetchUserMap = async () => {
  try {
    const res = await fetch(`${baseURL}/api/v1/usermapsample/map`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token")),
      }
    });
    if(res.ok){
      return await res.json();
    }else{
      throw new Error(res.statusText);
    }


  } catch (error) {
    throw new Error(error);
  }
};