import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { fetchProjectNameExists } from "../apidata/fetchProjectNameData";

export default function ProjectNameUpdate({
  priceVerItems,
  currentProject,
  update
}) {
  const { register, unregister, setValue, handleSubmit, errors } = useForm();

  useEffect(() => {
    setValue("name", currentProject.name);
    setValue("pricever", currentProject.pricever);
    setValue("discount", currentProject.discount);
    return () => unregister;
  }, [register, unregister, setValue, currentProject]);

  // 增加工程
  const onSubmit = data => {
    update(data);
  };

  const validateProjectNameDb = async name => {
    if(!currentProject)
    {
      return true;
    }
    if (name === currentProject.name) {
      return true;
    }
    try {
      const json = await fetchProjectNameExists(currentProject.customer_id,name);
      return !json || "已存在";
    } catch (error) {
      alert("exist api error!");
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group row">
        <label htmlFor="projectname" className="col-sm-1 col-form-label">
          工程名称:
        </label>
        <div className="col-sm-10">
          <input
            name="name"
            ref={register({
              minLength: {
                value: 1,
                message: "不能小于1个字"
              },
              maxLength: {
                value: 256,
                message: "不能大于256个字"
              },
              validate: value => validateProjectNameDb(value) //异步验证
            })}
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-sm-1">{errors.name && errors.name.message}</div>
      </div>

      <div className="form-group row">
        <label className="col-sm-1 col-form-label">版本</label>
        <div className="col-sm-5">
          <select
            name="pricever"
            ref={register({
              required: "不能为空"
            })}
          >
            {priceVerItems &&
              priceVerItems.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.name}
                </option>
              ))}
          </select>
          {errors.pricever?.message}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-1 col-form-label">折扣:</label>
        <div className="col-sm-5">
          <input
            ref={register({
              pattern: {
                value: /^[0-9]*\.?[0-9]*$/,
                message: "数字哦"
              },
              validate: {
                min: value => parseFloat(value, 10) >= 0 || "应该大于0",
                max: value => parseFloat(value, 10) <= 1 || "小于等于1"
              }
            })}
            name="discount"
            type="text"
          />
        </div>
        {errors.discount?.message}
      </div>

      <button type="submit" onClick={handleSubmit} className="btn btn-primary">
        保存修改
      </button>
    </form>
  );
}
