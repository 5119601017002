import React, { useState,useRef } from "react";

import { Link, useParams, useLocation } from "react-router-dom";


import "@reach/dialog/styles.css";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription
} from "@reach/alert-dialog";

import { fetchSampleStatusChange } from "../apidata/fetchSampleData";
import {
  payments,
  samplesources,
  sendways,
  testkinds,
  sampledealwiths
} from "../Enum";


export default function SampleDetail(props) {
  const { id } = useParams();
  const location = useLocation();
  const [msg, setMsg] = useState();
  const { data } = location;
  const [status,setStatus] = useState(data.status);



  const [showDialogCancel, setShowDialogCancel] = useState(false);
  const cancelRef = useRef();
  const openCancel = () => setShowDialogCancel(true);
  const closeCancel = () => setShowDialogCancel(false);

  const cancel = id => {
    (async () => {
      try {
        const json = await fetchSampleStatusChange(parseInt(id),-1);
        setMsg(json.msg);
        closeCancel();
        setStatus(-1);
        setTimeout(() => {
          setMsg(null);
        }, 2000);
      } catch (error) {
        setMsg(error.messge);
      }
    })();
  };

  return (
    <div>
      {msg}
      <h1>状态:{status < 0 ? "已作废" : "正常"}</h1>
      <h2>
          <Link to="/samplelist2">返回主查询</Link>
      </h2>
      <h2>
        <Link to="/samplelist">返回委托查看界面</Link>
      </h2>
      <h1>委托单详情</h1>
      <p>
        委托单号:
         {data.trust.certificate===0 ? "JHWT":"JHSWT"}-{data.trust.year}-{data.trust.trustnumber
          .toString()
          .padStart(4, "0")}
      </p>
      <p>
        任务单号:JHRW-{data.trust.year}-{data.trust.tasknumber
          .toString()
          .padStart(4, "0")}
      </p>
      <p>委托单位:{data.trust.trustname}</p>
      <p>工程名称:{data.trust.projectname} </p>
      <p>
        委托人:{data.trust.trustperson}
      </p>
      <p>
          委托人电话:{data.trust.trustpersonphone}
      </p>
      <p>
        样品单号:
        {data.num1===1 && `JHYP-${data.year}-${data.samplecode}-${data.samplenumber.toString().padStart(4, "0")}`}
        {data.num1 > 1 && [...Array(data.num1)].map((i,index)=>
          <span style={{display:"block"}} key={index}>{`JHYP-${data.year}-${data.samplecode}-${data.samplenumber.toString().padStart(4, "0")}(${index+1})`}</span>
        )}
      </p>
      <p>样品名称:{data.samplenickname} </p>
      <p>报告日期:{data.reportdate} </p>
      <p>{data.ismiddlereport ? `需要中间报告:${data.middlereportdate}`:"不需要中间报告"} </p>
      <p>委托日期:{data.trustdate} </p>
      <p>检测参数:</p>
      <ol>
        {data.arguments &&
          data.arguments.map(item => (
            <li key={item.id}>
              {item.stdcode}--{item.name}
              |{data.argumentsproperty &&
                  data.argumentsproperty.find(c => c.id === item.id) &&
                  data.argumentsproperty.find(c => c.id === item.id)?.property}
              --单价:{data.argumentsprice.find(c=>c.argument_id===item.id) && data.argumentsprice.find(c=>c.argument_id===item.id)?.price}元
            </li>
          ))}
      </ol>
        <p>{data.isone ? "整套" : "非整套"}</p>
      <p>参数金额:{data.isone ? data.onecost : ( data.argumentsprice
        ? data.argumentsprice.map(c => c.price).reduce((x, y) => x + y, 0)
            : 0)}</p>

      <p>附加附费用:</p>
      <ol>
        {data.extracost &&
          data.extracost.map(item => (
            <li key={item.id}>
              {item.name}--单价:{item.price}元x{item.qty}{item.unit}--{item.remark}
            </li>
          ))}
      </ol>
      <p>价格版本:{data.pricever}</p>
      <p>工程折扣:{data.discount}</p>
      <p>代码折扣:{data.codediscount}</p>
      <p>{data.othername}:{data.othercost} </p>
      <p>总费用:{data.totalcost} </p>
      <p>检测依据:</p>
      <ol>
        {data.accordings &&
          data.accordings.map(item => (
            <li key={item.id}>
              {item.stdcode}--{item.name}
            </li>
          ))}
      </ol>

      <p>判定依据:</p>
      <ol>
        {data.standards &&
          data.standards.map(item => (
            <li key={item.id}>
              {item.stdcode}--{item.name}
            </li>
          ))}
      </ol>
      <hr />


      <p>
        委托人签名日期:
        {data.trustpersondate}
      </p>
      <p>
        收样日期:{data.receivepersondate}
      </p>
      <p>样品处置:{sampledealwiths[data.trust.sampledealwith]}</p>
      <p>报告份数:{data.trust.reportqty}份</p>
      <p>报告领取方式:{sendways[data.trust.sendway]}</p>
      <p>寄送地址:{data.trust.address} </p>
      <hr />
      <p>取样人:{data.trust.sampleperson} </p>
      <p>取样人证号:{data.trust.sampleperson_cardnumber} </p>
      <p>见证单位:{data.trust.lookname} </p>
      <p>见证人:{data.trust.lookperson} </p>
      <p>见证证号:{data.trust.lookperson_cardnumber} </p>
      <p>抽样单位:{data.trust.samplingname} </p>
      <p>抽样人:{data.trust.samplingperson} </p>
      <p>抽样人证号:{data.trust.sampleperson_cardnumber} </p>
      <p>支付方式:{payments[data.trust.payment]}</p>
      <p>样品来源:{samplesources[data.trust.samplesource]}</p>
      <p>测试种类:{testkinds[data.trust.testkind]}</p>

      <hr />
      <p>工程部位:{data.projectpart} </p>
      <p>取样地址:{data.sampleaddress} </p>
      <p>样品名称:{data.samplenickname} </p>
      <p>样品描述:{data.samplestatus} </p>
      <p>样品数量:{data.numberofsample} </p>
      <p>样品组数:{data.num1} </p>
      <p>样品每组数量:{data.num2} </p>
      <p>规格/型号/牌号:{data.modelnumber} </p>
      <p>制造工厂:{data.makefactory} </p>
      <p>设计要求:{data.designrequire} </p>
      <p>委托文档:{data.trustdoc} </p>
      <p>备注:{data.remark}</p>
      <p>附加信息:</p>
      <ol>
        {data.extrainfo &&
          data.extrainfo.map(item => (
            <li key={item.id}>
              {item.print? "打印":"不打印"}|{item.name}:{item.value}
            </li>
          ))}
      </ol>

      <h2>
        <Link to="/samplelist">返回委托单查看界面</Link>
      </h2>
      <h2>
          <Link to="/samplelist2">返回主查询</Link>
      </h2>
      {status > -1 && (
        <h2>
          <button
            onClick={openCancel}
          >
          作废
          </button>
        </h2>
      )}
        {showDialogCancel && (
        <AlertDialog onDismiss={closeCancel} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center" }}>
            请再次确认是否作废委托单?
          </AlertDialogLabel>
          <AlertDialogDescription>{data.trust.projectname}</AlertDialogDescription>
          <div className="alert-buttons">
            <button onClick={()=>{cancel(id)}}>是, 作废</button>
            <button ref={cancelRef} onClick={closeCancel}>
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}
