import React, { useContext } from "react";

import { AuthContext } from "../App";

const About = () => {
  const { state } = useContext(AuthContext);

  return (
    <div> 
      <h1 style={{textAlign:"center"}}>广西交航LIS{state.isAuthenticated ? "欢迎你" : "请登录"}</h1>
      <h2>这里是news...</h2>
    </div>
  );
};

export default About;
