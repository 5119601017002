export const payments=["现金","转账","合同","年结"]
export const sampledealwiths=["检测方处理","委托方取回（限试验后2个月内，过期检测方自行处理）"]
export const sendways=["自取","邮寄"]
export const samplesources=["委托送样","委托取样","现场检测"]
export const testkinds = ["委托送样检测","见证取样检测","委托抽样检测","质量监督检测","仲裁检测","人员对比","仪器对比","能力验证","仪器软件适用性验证","比对试验","模拟试验","机构间比对","委托检测","样品复测"]
export const statusflags =  [
  { id: 0, name: "待发放" },
// { id: 1, name: "复核" },
  { id: 2, name: "退回" },
  { id: 3, name: "已发放" },
  { id: 4, name: "已接收" },
  { id: 5, name: "提交实验数据" },
  { id: 6, name: "中间报告" },
  { id: 7, name: "报告已批准" },
  { id: 8, name: "提交档案" },
  { id: 9, name: "档案员已接收" },
  { id: 10, name: "档案员已提交前台(归档)" },
  { id: 11, name: "前台接收报告" },
  { id: 12, name: "前台发放报告" },
  { id: -1, name: "作废" },
];

export const taskflags =  [
  { id: -2, name: "取消任务" },
  { id: -1, name: "退回任务" },
  { id: 0, name: "已发放任务" },
  { id: 1, name: "已接收任务" },
  { id: 2, name: "记录表已复核" },
  { id: 3, name: "报告已提交" },
  { id: 4, name: "报告已审核通过" },
  { id: 5, name: "报告已批准通过" },
  { id: 6, name: "标识完结" },
];
// 支付方式
export const payments1 = [
  { id: 0, name: "现金" },
  { id: 1, name: "转账" },
  { id: 2, name: "合同" },
  { id: 3, name: "年结" },

];
// 内外业
export const isoffices = [
  { id: 0, name: "外业" },
  { id: 1, name: "内业" }
];

// 样品处理
export const sampledealwiths1 = [
  { id: 0, name: "检测方处理" },
  { id: 1, name: "委托方取回" }
];
// 报告方式
export const sendways1 = [
  { id: 0, name: "自取" },
  { id: 1, name: "邮寄" }
];

// 样品来源
export const samplesources1 = [
  { id: 0, name: "委托送样" },
  { id: 1, name: "委托取样" },
  { id: 2, name: "现场检测" },
];

// 测试种类
export const testkinds1 = [
  { id: 0, name: "委托送样检测" },
  { id: 1, name: "见证取样检测" },
  { id: 2, name: "质量监督检测" },
  { id: 3, name: "仲裁检测" },
  { id: 4, name: "人员对比" },
  { id: 5, name: "仪器对比" },
  { id: 6, name: "能力验证" }
];
