import React, { useState } from "react";
import baseURL from "../Config";

import { AuthContext } from "../App";
import "../index.css";

export const Login = () => {
  const { dispatch } = React.useContext(AuthContext);

  //  初始用户信息
  const initialState = {
    username: "",
    password: "",
    isSubmitting: false,
    errorMessage: null
  };

  // 用户信息
  const [data, setData] = useState(initialState);

  const handleInputChange = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    });
  };

  // 提交
  const handleFormSubmit = event => {
    event.preventDefault();
    if (!data.username || !data.password) {
      return;
    }

    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null
    });
    fetch(`${baseURL}/api/v1/user/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: data.username,
        password: data.password
      })
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("非法用户");
      })
      .then(resJson => {
        dispatch({
          type: "LOGIN",
          // payload: resJson
          payload: {
            user: resJson.user,
            token: resJson.access_token,
            refreshtoken: resJson.refresh_token
          }
        });
      })
      .catch(error => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: error.message || error.statusText
        });
      });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="col-lg-12">
                <div className="p-5">
                  <div className="text-center">
                    <h1 className="h4 text-gray-900 mb-4">广西交航</h1>
                  </div>
                  <div className="row justify-content-center">
                    <form onSubmit={handleFormSubmit} className="form-group">
                      {/* <h1>Login</h1> */}
                      <label htmlFor="email">
                        用户名:
                        <input
                          type="text"
                          value={data.username}
                          onChange={handleInputChange}
                          name="username"
                          id="username"
                          className="form-control"
                        />
                      </label>
                      <br />

                      <label htmlFor="password">
                        密码:
                        <input
                          type="password"
                          value={data.password}
                          onChange={handleInputChange}
                          name="password"
                          id="password"
                          className="form-control"
                        />
                      </label>
                      <br />

                      {data.errorMessage && (
                        <span className="form-error">{data.errorMessage}</span>
                      )}
                      <br />
                      <button
                        disabled={data.isSubmitting}
                        className="btn btn-primary "
                      >
                        {data.isSubmitting ? "loading" : "登录"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
