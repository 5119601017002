import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import {
  fetchSampleQueryCount,
  fetchSamplesQuery,
} from "../apidata/fetchSampleData";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { css } from "emotion";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DateUtils } from "react-day-picker";
import { useAlert } from "react-alert";
import dateFnsFormat from "date-fns/format";
import format from "date-fns/format";
import dateFnsParse from "date-fns/parse";

import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";
import { fetchCustomers } from "../apidata/fetchCustomerData";
import {fetchFindProjectname, fetchProjectNameAll } from "../apidata/fetchProjectNameData";
import { fetchTrustPersons } from "../apidata/fetchTrustData";
import { fetchUserList } from "../apidata/fetchUser";
import {
  fetchSamplePaymentExcel,
  fetchSamplePaymentByTrustPersonExcel,
  fetchSamplePaymentByUseridExcel,
} from "../apidata/fetchSampleData";
import { subMonths, parseJSON } from "date-fns";
import { statusflags } from "../Enum";
import MyPortal from "../sample/MyPortal";
import { AuthContext } from "../App";
let kindStatus = 0;
let qStatus = "All";
let pageStatus = 1;
export default function SampleList2() {
  const { state } = useContext(AuthContext);
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [excelTrustPersonLoading, setExcelTrustPersonLoading] = useState(false);
  const [excelUseridLoading, setExcelUseridLoading] = useState(false);

  const [isFirst, setIsFirst] = useState(true);
  const [msg, setMsg] = useState(null);

  const selectKinds = [
    "全部",
    "委托单位",
    "委托人",
    "委托单号",
    "样品代码",
    "开单日期",
    "开单日期区间",
    "状态",
    "开单人",
    "委托人+开单人",
    "委托人+样品代码",
    "公路",
    "水运",
    "委托单位+工程",
    "仅按工程",
    "按任务单",
  ];
  // 总数
  const [count, setCount] = useState(0);

  // 样品代码
  const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  // 工程名称(异步)
  const [projectNameValuesAsync, setProjectNameValuesAsync] = useState();
  const [projectNameErrorAsync, setProjectNameErrorAsync] = useState();

  // 工程名称(基于委托单位)
  const [projectNameItems, setProjectNameItems] = useState([]); //db
  const [projectNameValues, setProjectNameValues] = useState();
  const [projectNameError, setProjectNameError] = useState();


  // 委托人
  const [trustPersonValues, setTrustPersonValues] = useState();
  const [trustPersonError, setTrustPersonError] = useState();

  // 开单人
  const [userItems, setUserItems] = useState();
  const [userError, setUserError] = useState();

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSampleCodeItems(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText);
      }
    })();
  }, []);

  // 加载开单人
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchUserList(1);
        setUserItems(json);
      } catch (error) {
        setUserError(error.message || error.statusText);
      }
    })();
  }, []);

  // 选择样品代码
  const handleChangeSampleCode = (selectedOption) => {
    setSampleCodeValues(selectedOption); // 控件的值
  };

  // pull server data  异步 委托单位数据
  const promiseOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 按委托单位取到对应的工程名称
  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchProjectNameAll(customerValues.id);
          setProjectNameItems(json);
        } catch (error) {
          setProjectNameError(error.message || error.statusText);
        }
      })();
    }}
   ,[customerValues]);

   // pull server data  异步 工程名称数据
  const promiseProjectOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchFindProjectname(inputValue);
      setProjectNameValues(null);
      return json;
    } catch (error) {
      setProjectNameError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };


  // 查询委托单位名称
  const handleCustomerChange = (event) => {
    setCustomerValues(event);
    return setCustomerError(null);
  };

  // 查询工程名称
  const handleProjectNameChange = e=>{
    setProjectNameValues(e);
    return setProjectNameError(null);
  }

   // 查询委托单位名称
   const handleProjectNameAsyncChange = (event) => {
    setProjectNameValuesAsync(event);
    return setProjectNameErrorAsync(null);
  };

  // pull server data  异步 委托人数据
  const promiseOptionsTrustPerson = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchTrustPersons(inputValue);
      setTrustPersonValues(null);
      return json;
    } catch (error) {
      setTrustPersonError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托人
  const handleTrustPersonChange = (event) => {
    setTrustPersonValues(event);
    return setTrustPersonError(null);
  };

  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  // 样品列表
  const [sampleItems, setSampleItems] = useState(null);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    //sessionStorage.setItem("page",JSON.stringify(pageNumber));
    pageStatus = pageNumber;
  };

  const [kind, setKind] = useState(0);
  const [q, setQ] = useState("All"); //查询条件

  const [taskNumber, setTaskNumber] = useState("2024-");
  const handleTaskNumberChange = (event) => {
    setTaskNumber(event.target.value);
  };
  const [trustNumber, setTrustNumber] = useState("2024-");
  const handleTrustNumberChange = (event) => {
    setTrustNumber(event.target.value);
  };
  const [create_at, setCreate_at] = useState(new Date());
  const [startDate, setStartDate] = useState(subMonths(Date.now(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [asc, setAsc] = useState(true);
  const [status, setStatus] = useState(0);
  const [userId, setUserId] = useState(() => {
    return state.user.id;
  });
  const [certificate,setCertificate] = useState("0");

  const handleAscChange = (event) => {
    setAsc(event.target.checked);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  const handleCertificateChange = (event) => {
    setCertificate(event.target.value);
  };

   // reset all control
  const resetControl =()=>{
     setCustomerValues(null);
     setProjectNameValues(null);
     setProjectNameValuesAsync(null);
     setTrustPersonValues(null);
     setTrustNumber("2024-");
     setTaskNumber("2024-")
     setSampleCodeValues(null);
     setCreate_at(new Date());
     setStartDate(subMonths(Date.now(), 1));
     setEndDate(new Date());
     setAsc(false);
     setStatus(0);
  }

  // 全查询
  const queryAll = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(0);
    setQ("All");
    kindStatus = 0;
    qStatus = "All";
    pageStatus = 1;

    resetControl(); // reset all control
  };
  const queryByCustomer = () => {
    if (customerValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(1);
      setQ({ customer_id: customerValues.id });
      kindStatus = 1;
      qStatus = customerValues;
      pageStatus = 1;
    }
  };
  const queryByTustperson = () => {
    if (trustPersonValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(2);
      setQ({ trustperson: trustPersonValues.trustperson });
      kindStatus = 2;
      qStatus = trustPersonValues;
      pageStatus = 1;
    }
  };

  const queryByTustNumber = () => {
    if (trustNumber.includes("-")) {
      const  temp=trustNumber.split("-")
      setQ(null);
      setCurrentPage(1);
      setKind(3);
      setQ({ trustnumber:parseInt(temp[1]), year: parseInt(temp[0]) });
      kindStatus = 3;
      qStatus = { trustnumber: trustNumber};
      pageStatus = 1;
    } else {
      return;
    }
  };

  const queryBySampleCode = () => {
    if (sampleCodeValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(4);
      setQ({ samplecode: sampleCodeValues.stdcode });
      kindStatus = 4;
      qStatus = sampleCodeValues;
      pageStatus = 1;
    }
  };

  const queryByCreate_at = () => {
    if (create_at) {
      setQ(null);
      setCurrentPage(1);
      setKind(5);
      setQ({ create_at: format(create_at, "yyyy-MM-dd") });
      kindStatus = 5;
      qStatus = create_at;
      pageStatus = 1;
    }
  };

  const queryByCreate_atRange = () => {
    if (startDate < endDate) {
      setQ(null);
      setCurrentPage(1);
      setKind(6);
      setQ({
        asc,
        startdate: format(startDate, "yyyy-MM-dd"),
        enddate: format(endDate, "yyyy-MM-dd"),
      });
      kindStatus = 6;
      qStatus = {
        asc,
        startDate,
        endDate,
      };
      pageStatus = 1;
    }
  };

  const queryByStatus = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(7);
    setQ({ status });
    kindStatus = 7;
    qStatus = status;
    pageStatus = 1;
  };

  const queryByUserId = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(8);
    setQ({ userid: userId });
    kindStatus = 8;
    qStatus = userId;
    pageStatus = 1;
  };

  const queryByTrustpersonAndUserId = () => {
    if (trustPersonValues && userId) {
      setQ(null);
      setCurrentPage(1);
      setKind(9);
      setQ({ trustperson: trustPersonValues.trustperson, userid: userId });
      kindStatus = 9;
      qStatus = { trustperson: trustPersonValues, userid: userId };
      pageStatus = 1;
    }
  };
  const queryByTrustpersonAndSamplecode = () => {
    if (trustPersonValues && sampleCodeValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(10);
      setQ({ trustperson: trustPersonValues.trustperson,samplecode:sampleCodeValues.stdcode });
      kindStatus = 10;
      qStatus = { trustperson: trustPersonValues, samplecode: sampleCodeValues };
      pageStatus = 1;
    }
  };

  // 按公路查询
  const queryRoad = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(11);
    setQ("Road");
    kindStatus = 11;
    qStatus = "Road";
    pageStatus = 1;

    resetControl(); // reset all control
  };
  // 按水运查询
  const queryWater = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(12);
    setQ("Water");
    kindStatus = 12;
    qStatus = "Water";
    pageStatus = 1;

    resetControl(); // reset all control
  };

  // 委托单位->工程名称
  const queryByProjectname = () => {
    if (projectNameValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(13);
      setQ({ project_id: projectNameValues.id });
      kindStatus = 13;
      qStatus = projectNameValues;
      pageStatus = 1;
    }
  };
  // 直接从工程名称(异步工程名称)
  const queryByProjectnameOnly = () => {
    if (projectNameValuesAsync) {
      setQ(null);
      setCurrentPage(1);
      setKind(14);
      setQ({ project_id: projectNameValuesAsync.id });
      kindStatus = 14;
      qStatus = projectNameValuesAsync;
      pageStatus = 1;
    }
  };

  const queryByTaskNumber = () => {
    if (taskNumber.includes("-")) {
      const  temp=taskNumber.split("-")
      setQ(null);
      setCurrentPage(1);
      setKind(17);
      setQ({ tasknumber:parseInt(temp[1]), year: parseInt(temp[0]) });
      kindStatus = 17;
      qStatus = { tasknumber: taskNumber};
      pageStatus = 1;
    } else {
      return;
    }
  };
  const queryBySamplecodeAndProject = () => {
    if (projectNameValues && sampleCodeValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(18);
      setQ({ project_id: projectNameValues.id,samplecode:sampleCodeValues.stdcode });
      kindStatus = 18;
      qStatus = { project: projectNameValues, samplecode: sampleCodeValues };
      pageStatus = 1;    
    }
  };
  // 恢复界面现场
  useEffect(() => {
    if (isFirst) {
      setKind(kindStatus);
      switch (kindStatus) {
        case 0:
          setQ(qStatus);
          break;
        case 1:
          setCustomerValues(qStatus);
          setQ({ customer_id: qStatus.id });
          break;
        case 2:
          setTrustPersonValues(qStatus);
          setQ({ trustperson: qStatus.trustperson });
          break;
        case 3:
          setTrustNumber(qStatus.trustnumber);
          const  temp=qStatus.trustnumber.split("-")
          setQ({ trustnumber:parseInt(temp[1]), year: parseInt(temp[0]) });
          break;
        case 4:
          setSampleCodeValues(qStatus);
          setQ({ samplecode: qStatus.stdcode });
          break;
        case 5:
          setCreate_at(parseJSON(qStatus));
          setQ({ create_at: format(parseJSON(qStatus), "yyyy-MM-dd") });
          break;
        case 6:
          setAsc(qStatus.asc);
          setStartDate(parseJSON(qStatus.startDate));
          setEndDate(parseJSON(qStatus.endDate));
          setQ({
            asc: qStatus.asc,
            startdate: format(parseJSON(qStatus.startDate), "yyyy-MM-dd"),
            enddate: format(parseJSON(qStatus.endDate), "yyyy-MM-dd"),
          });
          break;
        case 7:
          setStatus(qStatus);
          setQ({ status: parseInt(qStatus) });
          break;
        case 8:
          setUserId(qStatus);
          setQ({ userid: parseInt(qStatus) });
          break;
        case 9:
          setTrustPersonValues({
            id: qStatus.trustperson.id,
            trustperson: qStatus.trustperson.trustperson,
          });
          setUserId(qStatus.userid);
          setQ({
            trustperson: qStatus.trustperson.trustperson,
            userid: qStatus.userid,
          });
          break;
          case 10:
          setTrustPersonValues({
            id: qStatus.trustperson.id,
            trustperson: qStatus.trustperson.trustperson,
          });
          setSampleCodeValues(qStatus.samplecode);
          setQ({
            trustperson: qStatus.trustperson.trustperson,
            samplecode: qStatus.samplecode.stdcode,
          });
          break;
          case 11:
            setQ(qStatus);
            break;
          case 12:
            setQ(qStatus);
            break;
          case 13:
            setProjectNameValues(qStatus);
            setQ({ project_id: qStatus.id });
          break;
          case 14:
            setProjectNameValuesAsync(qStatus);
            setQ({ project_id: qStatus.id });
          break;
          case 17:
            setTaskNumber(qStatus.tasknumber);
            const  temp1=qStatus.tasknumber.split("-")
            setQ({ tasknumber:parseInt(temp1[1]), year: parseInt(temp1[0]) });
            break;
          case 18:                  
            setQ(qStatus);
            break;
        default:
          break;
      }
      setCurrentPage(pageStatus);
      setIsFirst(false);
    }
  }, [isFirst]);

  useEffect(() => {
    if (!isFirst) {
      if (q) {
        (async () => {
          try {
            setLoading(true);
            const temp = await fetchSampleQueryCount(kind, q);
            setCount(temp);
            setLoading(false);
          } catch (error) {
            setMsg(
              `系统异常,请联系开发公司.:${error.message || error.statusText}`
            );
          }
        })();
      }
    }
    return () => {
      setCount(0);
    };
  }, [kind, q, isFirst]);

  useEffect(() => {
    if (!isFirst) {
      if (q) {
        (async () => {
          try {
            setLoading(true);
            const data = { ...q, pagesize: pageSize, page: currentPage };
            const temp = await fetchSamplesQuery(kind, data);
            setSampleItems(temp);
            setLoading(false);
          } catch (error) {
            setMsg(
              `当前查询信息不存在,请检查.${error.message || error.statusText}`
            );
          }
        })();
      }
    }
    return () => {
      setSampleItems(null);
    };
  }, [kind, q, pageSize, currentPage, isFirst]);

  const exportExcel = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日");
      return;
    }
    if (diffdays > 93) {
      alert.error("日期区间不能大于3个月,大于3个月的请分开多导出,每次导3个月就可以");
      return;
    }
    setExcelLoading(true);
    try {
      const res = await fetchSamplePaymentExcel(
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd"),
        asc ? "asc" : "desc",
        parseInt(certificate)
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      const temp = certificate==="0" ? "公路" : "水运";
      link.download = `${temp}对帐单${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
    }
    setExcelLoading(false);
  };

  const exportExcelByTrustPerson = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日");
      return;
    }
    if (diffdays > 183) {
      alert.error("日期区间不能大于6个月,在于6月的请分开两次导出");
      return;
    }
    if (!trustPersonValues) {
      alert.error("没有选到委托人");
      setMsg("没有选到委托人");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    setExcelTrustPersonLoading(true);
    try {
      const res = await fetchSamplePaymentByTrustPersonExcel(
        trustPersonValues.trustperson,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd"),
        asc ? "asc" : "desc"
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `${
        trustPersonValues.trustperson
      }对帐单${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
    }
    setExcelTrustPersonLoading(false);
  };

  const exportExcelByUserid = async () => {
    const diffdays =
      (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    if (diffdays < 1) {
      alert.error("开始日期需要小于结束日");
      return;
    }
    if (diffdays > 183) {
      alert.error("日期区间不能大于6个月,在于6月的请分开两次导出");
      return;
    }
    if (!userId) {
      alert.error("没有选到开单人");
      setMsg("没有选到开单人");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
      return;
    }

    setExcelUseridLoading(true);
    try {
      const res = await fetchSamplePaymentByUseridExcel(
        userId,
        format(startDate, "yyyy-MM-dd"),
        format(endDate, "yyyy-MM-dd"),
        asc ? "asc" : "desc"
      );
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `我的对帐单${startDate.toLocaleDateString()}至${endDate.toLocaleDateString()}`;
      link.click();
      window.URL.revokeObjectURL(url);
      alert.success("导出成功");
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 3000);
    } catch (error) {
      alert.error("导出失败" + error.message);
      setMsg("导出失败" + error.message);
    }
    setExcelUseridLoading(false);
  };
  const deleteUi = (delete_id) => {
    setMsg("删除成功");
    setSampleItems(sampleItems.filter((c) => c.id !== delete_id));
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };

  const changeStatusUi = (id,status) => {
    if (status===-1){
       setMsg("作废成功");
    }else{
      setMsg("恢复成功");
    }
    setSampleItems(sampleItems.map(c => c.id ===id ? {...c,status:status}:c) );
    setTimeout(() => {
      setMsg(null);
    }, 3000);
  };


  return (
    <div>
      <div>
        <div className={css`
               margin-bottom: 5px;
            `} >
          <label>委托单位:</label>
          <AsyncSelect
            name="trustname"
            className={css`
              width: 80%;
              height: atuo;
              display: inline-block;
            `}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={handleCustomerChange}
            getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
            value={customerValues}
            placeholder="请输入委托单位名称"
          />
          {customerError}
          <button
            className={
              kind === 1
                ? css`
                    display: inlneblock;
                    padding: 4px 15px;
                    background-color: #1890ff;
                    color: #ffffff;
                    margin-left:5px;
                  `
                : css`
                    display: inlneblock;
                    margin-left:5px;
                  `
            }
            onClick={queryByCustomer}
          >
            按委托单位查询
          </button>
        </div>
      </div>

      <div className={css`
               margin-bottom: 5px;
            `}>
          <label >工程名称:</label>
              <Select
                  className={css`
                  width: 80%;
                  height: atuo;
                  display: inline-block;
                `}
                name="project"
                placeholder="请选输入委托单位后,再来选择工程名称(仅能选择)"
                value={projectNameValues}
                options={projectNameItems}
                onChange={handleProjectNameChange}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option.name}
              />
               <button
                  className={
                    kind === 13
                      ? css`
                          display: inlneblock;
                          padding: 4px 15px;
                          background-color: #1890ff;
                          color: #ffffff;
                          margin-left:5px;
                        `
                      : css`
                          display: inlneblock;
                          margin-left:5px;
                        `
                  }
                  onClick={queryByProjectname}
                >
                按委托单位+工程查询
                </button>
              <div style={{ display: "inlneblock", color: "red" }}>
                {projectNameError}
              </div>
      </div>


      <div className={css`
               margin-bottom: 5px;
            `} >
          <label>工程名称:</label>
          <AsyncSelect
            name="trustname"
            className={css`
              width: 80%;
              height: atuo;
              display: inline-block;
            `}
            cacheOptions
            defaultOptions
            loadOptions={promiseProjectOptions}
            getOptionValue={(option) => option["id"]}
            onChange={handleProjectNameAsyncChange}
            getOptionLabel={(option) => `${option.id} | ${option.name}`}
            value={projectNameValuesAsync}
            placeholder="请直接输入工程名称"
          />
          {projectNameErrorAsync}
          <button
            className={
              kind === 14
                ? css`
                    display: inlneblock;
                    padding: 4px 15px;
                    background-color: #1890ff;
                    color: #ffffff;
                    margin-left:5px;
                  `
                : css`
                    display: inlneblock;
                    margin-left:5px;
                  `
            }
            onClick={queryByProjectnameOnly}
          >
            仅按工程查询
          </button>
        </div>

      <div className="form-group">
        <label>委 托 人 :</label>
        <AsyncSelect
          name="trustperson"
          className={css`
            width: 20%;
            height: atuo;
            display: inline-block;
          `}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptionsTrustPerson}
          getOptionValue={(option) => option.id}
          onChange={handleTrustPersonChange}
          getOptionLabel={(option) => option.trustperson}
          value={trustPersonValues}
          placeholder="请输入委托人"
        />
        {trustPersonError}
        <button
          className={
            kind === 2
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByTustperson}
        >
          查询委托人
        </button>
        <label
          className={css`
            margin-left: 50px;
          `}
        >
          开单人:
        </label>
        <select name="user" value={userId} onChange={handleUserIdChange}>
        <option disabled value="">请选择开单人</option>
          {userItems &&
            userItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.realname}
              </option>
            ))}
        </select>
        <button
          className={
            kind === 8
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByUserId}
        >
          按开单人过滤
        </button>
        {userError}
        <button
          className={
            kind === 9
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 80px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 20px;
                `
          }
          onClick={queryByTrustpersonAndUserId}
        >
          按委托人+开单人过滤
        </button>

      </div>

      <div className="form-group">
        <label>样品代码:</label>
        <Select
          className={css`
            width: 20%;
            display: inline-block;
          `}
          name="samplecode"
          placeholder="样品代码"
          value={sampleCodeValues}
          options={sampleCodeItems}
          onChange={handleChangeSampleCode}
          // react-select/issues/3018
          getOptionValue={(option) => option["id"]}
          getOptionLabel={(option) => `${option.stdcode} | ${option.category.certificate===0? "公路" : "水运"} | ${option.name}`}
        />
        {sampleCodeError}
        <button
          className={
            kind === 4
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryBySampleCode}
        >
          按代码查询
        </button>

        <button
          className={
            kind === 10
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 40px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 40px;
                `
          }
          onClick={queryByTrustpersonAndSamplecode}
        >
          委托人+样品代码
        </button>

        <button
          className={
            kind === 10
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 40px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 40px;
                `
          }
          onClick={queryBySamplecodeAndProject}
        >
          工程+样品代码
        </button>


        <label
          className={css`
            margin-left: 20px;
          `}
        >
          开单日期:
        </label>
        <DayPickerInput
          value={create_at}
          formatDate={formatDate}
          format={"yyyy-MM-dd"}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(new Date(), "yyyy-MM-dd")}`}
          onDayChange={(day) => {
            setCreate_at(day);
          }}
        />
        <button
          className={
            kind === 5
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByCreate_at}
        >
          开单日期查询
        </button>
      </div>

      <div className="form-group">
      <label
          className={css`
          margin-right: 15px;
        `}
        >
          任务单:
        </label>
        <input
          placeholder="格式2021-1111"
          value={taskNumber}
          onChange={handleTaskNumberChange}
          type="text"
          maxLength="10"
        />
        <button
          className={
            kind === 17
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 5px;
                  display: inlneblock;
                `
          }
          onClick={queryByTaskNumber}
        >
          任务单
        </button>

        <label
          className={css`
            margin-left: 20px;
          `}
        >
          委托单:
        </label>
        <input
          placeholder="格式2021-1111"
          value={trustNumber}
          onChange={handleTrustNumberChange}
          type="text"
          maxlength="10"
        />
        <button
          className={
            kind === 3
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 5px;
                  display: inlneblock;
                `
          }
          onClick={queryByTustNumber}
        >
          委托单
        </button>

        <label
          className={css`
            margin-left: 30px;
          `}
        >
          状态:
        </label>
        <select name="status" value={status} onChange={handleStatusChange}>
          <option disabled value="">请选择状态</option>
          {statusflags.map(item=>(
              <option key={item.id} value={item.id}>{item.name}</option>
          ))}
        </select>
        <button
          className={
            kind === 7
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByStatus}
        >
          过滤
        </button>

        <button
          className={
            kind === 0
              ? css`
                  margin-left: 30px;
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 30px;
                  display: inlneblock;
                `
          }
          onClick={queryAll}
        >
          清空查询条件
        </button>

        <span
          className={css`
            margin-left: 10px;
          `}
        >
          按{selectKinds[kind]}查询中...
        </span>
      </div>



      <div className="form-group">
      <button
          className={
            kind === 11
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 0px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 0px;
                `
          }
          onClick={queryRoad}
        >
          公路
        </button>
        <button
          className={
            kind === 12
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 20px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 20px;
                `
          }
          onClick={queryWater}
        >
          水运
        </button>
        <label>开始日期:</label>
        <DayPickerInput
          value={startDate}
          formatDate={formatDate}
          format={"yyyy-MM-dd"}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(
            subMonths(new Date(), 1),
            "yyyy-MM-dd"
          )}`}
          onDayChange={(day) => setStartDate(day)}
        />
        <label>结束日期:</label>
        <DayPickerInput
          value={endDate}
          formatDate={formatDate}
          format={"yyyy-MM-dd"}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(new Date(), "yyyy-MM-dd")}`}
          onDayChange={(day) => setEndDate(day)}
        />
        <label>是否顺序:</label>
        <input
          name="asc"
          checked={asc}
          onChange={handleAscChange}
          type="checkbox"
        />
        <button
          className={
            kind === 6
              ? css`
                  margin-left: 10px;
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 10px;
                  display: inlneblock;
                `
          }
          onClick={queryByCreate_atRange}
        >
          区间查询
        </button>
        <select name="certificate" value={certificate} onChange={handleCertificateChange}
          className={
               css`
                    display: inlneblock;
                    margin-left: 10px;
                  `
            }
          >
          <option value="0">公路</option>
          <option value="1">水运</option>
        </select>
        <button
          className={css`
            margin-left: 20px;
            display: inlneblock;
          `}
          disabled={excelLoading}
          onClick={() => {
            exportExcel();
          }}
        >
          {excelLoading ? "导出中..." : "导出excel"}
        </button>

        <button
          disabled={excelTrustPersonLoading}
          className={css`
            display: inlneblock;
            margin-left: 20px;
          `}
          onClick={() => {
            exportExcelByTrustPerson();
          }}
        >
          {excelTrustPersonLoading ? "导出中..." : " 按委托人+日期区间导出"}
        </button>

        <button
          disabled={excelUseridLoading}
          className={css`
            display: inlneblock;
            margin-left: 20px;
          `}
          onClick={() => {
            exportExcelByUserid();
          }}
        >
          {excelUseridLoading ? "导出中..." : " 按开单人+日期区间导出"}
        </button>
      </div>
      {msg}
      <div>
      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      </div>

      <table className="table table-sm table-hover">
        <thead className="thead-dark">
          <tr>
            <th>序号</th>
            <th>委托单号</th>
            <th>委托人</th>
            <th>状态</th>
            <th>任务编号</th>
            <th>样品编号</th>
            <th>样品名称</th>
            <th>样品数量</th>
            <th>规格型号</th>
            <th>金额</th>
            <th>报告日期</th>
            <th>委托日期</th>
            <th>工程名称</th>
            <th>委托单位</th>
            <th>开单</th>
            <th>操作</th>
            <th>操作</th>
            <th>操作</th>
          </tr>
        </thead>
        <caption>当前查询共{count}条</caption>
        <tbody>
          {sampleItems ? (
            sampleItems.map((item, index) => (
              <tr key={item.id} style={{color:item.status===-1 ? "red" : null}} >
                <td>{index + 1}</td>
                <td>
                  <Link
                    to={{
                      pathname: `/sampledetail/${item.id}`,
                      data: item,
                    }}
                  >
                    {item.trust.certificate===0 ? "JHWT":"JHSWT"}{`-${
                      item.trust.year
                    }-${item.trust.trustnumber.toString().padStart(4, "0")}`}
                  </Link>
                </td>
                <td>{item.trust.trustperson}</td>
                <td>{statusflags?.find((c) => c.id === item.status)?.name}</td>
                <td>
                  {`JHRW-${item.trust.year}-${item.trust.tasknumber.toString().padStart(4, "0")}`}
                </td>
                <td>
                  {`JHYP-${item.year}-${
                    item.samplecode
                  }-${item.samplenumber.toString().padStart(4, "0")}`}
                </td>
                <td>
                  {item.samplenickname?.length > 6
                    ? item.samplenickname?.substring(0, 6) + "..."
                    : item.samplenickname}
                </td>
                <td>
                  {item.numberofsample?.length > 8
                    ? item.numberofsample?.substring(0, 8) + "..."
                    : item.numberofsample}
                </td>
                <td>
                  {item.modelnumber?.length > 10
                    ? item.modelnumber?.substring(0, 10) + "..."
                    : item.modelnumber}
                </td>
                <td>{item.totalcost}</td>
                <td>{item.reportdate}</td>
                <td>{item.trustdate}</td>
                <td>
                  {item.trust.projectname?.length > 9
                    ? item.trust.projectname?.substring(0, 9) + "..."
                    : item.trust.projectname}
                </td>
                <td>
                  {item.trust.trustname?.length > 6
                    ? item.trust.trustname?.substring(0, 6) + "..."
                    : item.trust.trustname}
                </td>

                <td>
                  {userItems?.find((c) => c.id === item.user_id)?.realname}
                </td>
                <td>
                  <Link
                    to={{
                      pathname: `/samplejh/${item.id}`,
                      data: item,
                      operate: "edite",
                    }}
                  >
                    修改
                  </Link>
                </td>
                <td>
                  <Link
                    to={{
                      pathname: `/samplejh/${item.id}`,
                      data: item,
                      operate: "copy",
                    }}
                  >
                    复制
                  </Link>
                </td>
                <td>
                  <MyPortal item={item} deleteUi={deleteUi} changeStatusUi={changeStatusUi} />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>
    </div>
  );
}

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}
