import React, { useState, useEffect,useContext,useRef } from "react";
import Pagination from "../components/Pagination";

import Select from "react-select";
import AsyncSelect from "react-select/async";
import { css } from "emotion";

import { useAlert } from "react-alert";
import { Dialog } from "@reach/dialog";
import {
  AlertDialog,
  AlertDialogLabel,
  AlertDialogDescription,
} from "@reach/alert-dialog";
import "@reach/dialog/styles.css";


import "@reach/dialog/styles.css";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import { DateUtils } from "react-day-picker";

import dateFnsFormat from "date-fns/format";
import format from "date-fns/format";
import dateFnsParse from "date-fns/parse";

import {statusflags} from "../Enum";
import {
  fetchSampleQueryCount,
  fetchSamplesQuery
} from "../apidata/fetchSampleData";
import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";
import { fetchCustomers } from "../apidata/fetchCustomerData";
import { fetchTrustPersons } from "../apidata/fetchTrustData";
import { subMonths } from "date-fns";

import { not, intersection, union } from "../common/CheckHelper";
import { fetchUserList } from "../apidata/fetchUser";


import { AuthContext } from "../App";
import { fetchCancelTask,fetchUserMap } from "../apidata/fetchTask";


import SendReportForm from "./SendReportForm";
import SendTask from "./SendTask";
import TaskDetail from "./TaskDetail";

export default function SampleList3() {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(null);
  const alert = useAlert();

  const selectKinds = [
    "全部",
    "委托单位",
    "委托人",
    "委托单号",
    "样品代码",
    "开单日期",
    "开单日期区间",
    "状态",
    "开单人",
    "委托人+开单人",
    "委托人+样品代码",
    "公路",
    "水运",
    "委托单位+工程",
    "仅按工程",
    "按状态+开单人",
    "按样品代码+状态+开单人",
  ];

  const [currentSample,setCurrentSample] = useState();
  //  显示任务信息
  const [showDialogTask, setShowDialogTask] = useState(false);
  const openTask = () => setShowDialogTask(true);
  const closeTask = () => setShowDialogTask(false);

  //  取消任务
  const [showDialogCancelTask, setShowDialogCancelTask] = useState(false);
  const cancelRef = useRef();
  const openCancelTask = () => setShowDialogCancelTask(true);
  const closeCancelTask = () => setShowDialogCancelTask(false);


   // 开单人
   const [userItems, setUserItems] = useState();
   const [userError, setUserError] = useState();

     // 人与样品代码映射表
  const [usermapItems, setUsermapItems] = useState([]);

  // 总数
  const [count, setCount] = useState(0);

  // 样品代码
  const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState(null);
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  // 委托人
  const [trustPersonValues, setTrustPersonValues] = useState();
  const [trustPersonError, setTrustPersonError] = useState();

  // 待选的id集
  const [items, setItems] = useState([]);
  // 已选的id集
  const [checked, setChecked] = useState([]);

  // 修改样品单 status 同时记录发货信息
  const [showDialogUpdate, setShowDialogUpdate] = useState(false);
  const openUpdate = () => setShowDialogUpdate(true);
  const closeUpdate = () => setShowDialogUpdate(false);

    // 发放任务
    const [showDialogSendTask, setShowDialogSendTask] = useState(false);
    const openSendTask = () => setShowDialogSendTask(true);
    const closeSendTask = () => setShowDialogSendTask(false);


 const [userId, setUserId] = useState(() => {
      return state.user.id;
  });

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSampleCodeItems(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText);
      }
    })();
  }, []);

    // 加载开单人
    useEffect(() => {
      (async () => {
        try {
          const json = await fetchUserList(1);
          setUserItems(json);
        } catch (error) {
          setUserError(error.message || error.statusText);
        }
      })();
    }, []);

     // 加载映射表
     useEffect(() => {
      (async () => {
        try {
          const json = await fetchUserMap();
          setUsermapItems(json);
        } catch (error) {
          alert.error(error.message);
        }
      })();
    }, [alert]);

  // 选择样品代码
  const handleChangeSampleCode = selectedOption => {
    setSampleCodeValues(selectedOption); // 控件的值
  };

  // pull server data  异步 委托单位数据
  const promiseOptions = async inputValue => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托单位名称
  const handleCustomerChange = event => {
    setCustomerValues(event);
    return setCustomerError(null);
  };

  // pull server data  异步 委托人数据
  const promiseOptionsTrustPerson = async inputValue => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchTrustPersons(inputValue);
      setTrustPersonValues(null);
      return json;
    } catch (error) {
      setTrustPersonError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托人
  const handleTrustPersonChange = event => {
    console.log(event);
    setTrustPersonValues(event);
    return setTrustPersonError(null);
  };



  //分页相关
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(15);
  // 样品列表
  const [sampleItems, setSampleItems] = useState(null);

  // Change page
  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const [kind, setKind] = useState(0);
  const [q, setQ] = useState("All"); //查询条件

  const [trustNumber, setTrustNumber] = useState();
  const handleTrustNumberChange = event => {
    setTrustNumber(parseInt(event.target.value));
  };
  const [create_at, setCreate_at] = useState(new Date());
  const [startDate, setStartDate] = useState(subMonths(Date.now(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [asc, setAsc] = useState(false);
  const [status, setStatus] = useState(0);

  const handleAscChange = event => {
    setAsc(event.target.checked);
    console.log(event.target.checked);
  };

  const handleStatusChange = event => {
    setStatus(event.target.value);
  };

  // 全查询
  const queryAll = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(0);
    setQ("All");

    // reset all control
    setCustomerValues(null);
    setTrustPersonValues(null);
    setTrustNumber(NaN);
    setSampleCodeValues(null);
    setCreate_at(new Date());
    setStartDate(subMonths(Date.now(), 1));
    setEndDate(new Date());
    setAsc(false);
    setStatus(0);
  };
  const queryByCustomer = () => {
    if (customerValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(1);
      setQ({ customer_id: customerValues.id });
    }
  };
  const queryByTustperson = () => {
    if (trustPersonValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(2);
      setQ({ trustperson: trustPersonValues.trustperson });
    }
  };

  const queryByTustNumber = () => {
    if (trustNumber < 100000 && trustNumber > 0) {
      setQ(null);
      setCurrentPage(1);
      setKind(3);
      setQ({ trustnumber: trustNumber, year: new Date().getFullYear() });
    } else {
       return;
    }
  };

  const queryBySampleCode = () => {
    if (sampleCodeValues) {
      setQ(null);
      setCurrentPage(1);
      setKind(4);
      setQ({ samplecode: sampleCodeValues.stdcode });
    }
  };

  const queryByCreate_at = () => {
    if (create_at) {
      setQ(null);
      setCurrentPage(1);
      setKind(5);
      setQ({ create_at: format(create_at, "yyyy-MM-dd") });
    }
  };

  const queryByCreate_atRange = () => {
    if (startDate < endDate) {
      setQ(null);
      setCurrentPage(1);
      setKind(6);
      setQ({
        asc,
        startdate: format(startDate, "yyyy-MM-dd"),
        enddate: format(endDate, "yyyy-MM-dd")
      });
    }
  };

  const queryByStatus = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(7);
    setQ({ status });
  };

  const queryByUserId = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(8);
    setQ({ userid: userId });
  };

  const queryByStatusAndUserId = () => {
    setQ(null);
    setCurrentPage(1);
    setKind(15);
    setQ({ status,userid:userId });
  };

  const queryBySamplecodeAndStatusAndUserId = () => {
    if(sampleCodeValues){
      setQ(null);
      setCurrentPage(1);
      setKind(16);
      setQ({ samplecode:sampleCodeValues.stdcode,status,userid:userId });
    }
  };




  useEffect(() => {
    if (q) {
      (async () => {
        try {
          setLoading(true);
          const temp = await fetchSampleQueryCount(kind, q);
          setCount(temp);
          console.log(temp);
          setLoading(false);
        } catch (error) {
          setMsg(
            `系统异常,请联系开发公司.:${error.message || error.statusText}`
          );
        }
      })();
    }
  }, [kind, q]);

  useEffect(() => {
    if (q) {
      (async () => {
        try {
          setLoading(true);
          const data = { ...q, pagesize: pageSize, page: currentPage };
          const temp = await fetchSamplesQuery(kind, data);
          setSampleItems(temp);
          setItems(temp.map(c => c.id));
          setChecked([]);
          setLoading(false);
        } catch (error) {
          setMsg(
            `系统异常,请联系开发公司.:${error.message || error.statusText}`
          );
        }
      })();
    }
  }, [kind, q, pageSize, currentPage]);



  // 单选
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);  // 不在选择集合,加进去
    } else {
      newChecked.splice(currentIndex, 1);  // 在选择的集合里,拿出来
    }

    setChecked(newChecked);
  };

  // 已选数量
  const numberOfChecked = items => intersection(checked, items).length;

  // 全选
  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {  //已全选情况,置空
      setChecked(not(checked, items));
    } else {  // 部分选择,或者没有选
      setChecked(union(checked, items));
    }
  };
 // 发任务
  const changeUiRw = (ms)=>{
    closeSendTask();
    const updateItem = sampleItems.map((item, index) => {
      return checked.indexOf(item.id) !== -1 ? {...item,status:3} : item;
    });
    setSampleItems(updateItem);
    setChecked([])

  }
  // 发放报告
  const changeUiReport = (ms)=>{
    closeUpdate();
    const updateItem = sampleItems.map((item, index) => {
      return checked.indexOf(item.id) !== -1 ? {...item,status:12} : item;
    });
    setSampleItems(updateItem);
    setChecked([])

  }


  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  const cancelTask = async () => {
   const sample =  sampleItems.find(c=>c.id===checked[0]);
   if (sample.status > 3){
     alert.info("不能撒回了,实验员接了,需要口头通知实验员退回");
     return;
   }
    try {
      const json = await fetchCancelTask(checked[0]);
      alert.success(json.msg);
      closeCancelTask();
      const updateItem = sampleItems.map((item, index) => {
        return checked.indexOf(item.id) !== -1 ? {...item,status:0} : item;
      });
      setSampleItems(updateItem);
      setChecked([])
    } catch (error) {
      alert.error(error.message+error.status);
    }
  };


  return (
    <div>
      <div>
        <div className="form-group">
          <label>委托单位:</label>
          <AsyncSelect
            name="trustname"
            className={css`
              width: 80%;
              height: atuo;
              display: inline-block;
            `}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            getOptionValue={(option) => option["id"]}
            onChange={handleCustomerChange}
            getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
            value={customerValues}
            placeholder="请输入委托单位名称"
          />
          {customerError}
          <button
            className={
              kind === 1
                ? css`
                    display: inlneblock;
                    padding: 4px 15px;
                    background-color: #1890ff;
                    color: #ffffff;
                  `
                : css`
                    display: inlneblock;
                  `
            }
            onClick={queryByCustomer}
          >
            按委托单位查询
          </button>
        </div>
      </div>

      <div className="form-group">
        <label>委 托 人 :</label>
        <AsyncSelect
          name="trustperson"
          className={css`
            width: 20%;
            height: atuo;
            display: inline-block;
          `}
          cacheOptions
          defaultOptions
          loadOptions={promiseOptionsTrustPerson}
          getOptionValue={(option) => option.id}
          onChange={handleTrustPersonChange}
          getOptionLabel={(option) => option.trustperson}
          value={trustPersonValues}
          placeholder="请输入委托人"
        />
        {trustPersonError}
        <button
          className={
            kind === 2
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByTustperson}
        >
          查询委托人
        </button>
        <label
          className={css`
            margin-left: 20px;
          `}
        >
          委托单号:
        </label>
        <input
          placeholder="委托单后4位或5位"
          value={trustNumber}
          onChange={handleTrustNumberChange}
          type="number"
        />
        <button
          className={
            kind === 3
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 5px;
                  display: inlneblock;
                `
          }
          onClick={queryByTustNumber}
        >
          委托单号查询
        </button>
        <button
          className={
            kind === 0
              ? css`
                  margin-left: 50px;
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 50px;
                  display: inlneblock;
                `
          }
          onClick={queryAll}
        >
          清空查询条件
        </button>

        <span
          className={css`
            margin-left: 30px;
          `}
        >
          正在按{selectKinds[kind]}查询中...
        </span>
      </div>



      <div className="form-group">
        <label>开始日期:</label>
        <DayPickerInput
          value={startDate}
          formatDate={formatDate}
          format={"yyyy-MM-dd"}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(
            subMonths(new Date(), 1),
            "yyyy-MM-dd"
          )}`}
          onDayChange={(day) => setStartDate(day)}
        />
        <label>结束日期:</label>
        <DayPickerInput
          value={endDate}
          formatDate={formatDate}
          format={"yyyy-MM-dd"}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(new Date(), "yyyy-MM-dd")}`}
          onDayChange={(day) => setEndDate(day)}
        />
        <label>是否顺序:</label>
        <input
          name="asc"
          checked={asc}
          onChange={handleAscChange}
          type="checkbox"
        />
        <button
          className={
            kind === 6
              ? css`
                  margin-left: 10px;
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  margin-left: 10px;
                  display: inlneblock;
                `
          }
          onClick={queryByCreate_atRange}
        >
          区间查询
        </button>

        <label
          className={css`
            margin-left: 20px;
          `}
        >
          开单日期:
        </label>
        <DayPickerInput
          value={create_at}
          formatDate={formatDate}
          format={"yyyy-MM-dd"}
          parseDate={parseDate}
          placeholder={`${dateFnsFormat(new Date(), "yyyy-MM-dd")}`}
          onDayChange={(day) => {
            setCreate_at(day);
          }}
        />
        <button
          className={
            kind === 5
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByCreate_at}
        >
          开单日期查询
        </button>
      </div>


      <div className="form-group">
        <label>样品代码:</label>
        <Select
          className={css`
            width: 20%;
            display: inline-block;
          `}
          name="samplecode"
          placeholder="样品代码"
          value={sampleCodeValues}
          options={sampleCodeItems}
          onChange={handleChangeSampleCode}
          // react-select/issues/3018
          getOptionValue={(option) => option["id"]}
          getOptionLabel={(option) => `${option.stdcode} | ${option.name}`}
        />
        {sampleCodeError}
        <button
          className={
            kind === 4
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryBySampleCode}
        >
          查询代码
        </button>


        <label
          className={css`
            margin-left: 50px;
          `}
        >
          状态
        </label>
        <select name="status" value={status} onChange={handleStatusChange}>
          <option disabled value="">
            请选择状态
          </option>
          {statusflags.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <button
          className={
            kind === 7
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByStatus}
        >
          过滤
        </button>
        <label
          className={css`
            margin-left: 50px;
          `}
        >
          开单人:
        </label>
        <select name="user" value={userId} onChange={handleUserIdChange}>
          <option disabled value="">请选择开单人</option>
          {userItems &&
            userItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.realname}
              </option>
            ))}
        </select>
        <button
          className={
            kind === 8
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                `
              : css`
                  display: inlneblock;
                `
          }
          onClick={queryByUserId}
        >
          按开单人过滤
        </button>
        {userError}
      </div>

      <div className="form-group">
      <button
          className={css`
            margin-left: 30px;
          `}
          disabled={checked.length === 0}
          onClick={()=>{
            openSendTask();
          }}
        >
          批量发放任务
        </button>

        <button
          className={css`
            margin-left: 30px;
          `}
          disabled={checked.length !==1}
          onClick={()=>{
             openCancelTask();
          }}
        >
          撤回任务
        </button>
        <span>撒回只能单选</span>
        <button
          className={
            kind === 15
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 30px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 30px;
                `
          }
          onClick={queryByStatusAndUserId}
        >
          按状态+开单人(过滤)
        </button>




        <button
           className={
            kind === 16
              ? css`
                  display: inlneblock;
                  padding: 4px 15px;
                  background-color: #1890ff;
                  color: #ffffff;
                  margin-left: 30px;
                `
              : css`
                  display: inlneblock;
                  margin-left: 30px;
                `
          }
          disabled={sampleCodeValues===null}
          onClick={queryBySamplecodeAndStatusAndUserId}
        >
          按样品代码+状态+开单人(过滤)
        </button>

        <button
          className={css`
            margin-left: 30px;
          `}
          disabled={checked.length === 0}
          onClick={() => {
            openUpdate();
          }}
        >
          批量发放报告
        </button>
      </div>
      {msg}

      <table className="table table-striped">
        <thead>
          <tr>
            <th
              className={css`
                width: 15%;
              `}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={
                      numberOfChecked(items) === items.length &&
                      items.length !== 0
                    }
                    indeterminate={
                      numberOfChecked(items) !== items.length &&
                      numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0}
                    inputProps={{ "aria-label": "all items selected" }}
                  />
                }
                label={"全选"}
              />
            </th>
            <th
              className={css`
                width: 8%;
              `}
            >
               状态
            </th>
            <th
              className={css`
                width: 8%;
              `}
            >
              开单人
            </th>
            <th
              className={css`
                width: 8%;
              `}
            >
              收样日期
            </th>
            <th
              className={css`
                width: 8%;
              `}
            >
              委托人
            </th>
            <th
              className={css`
                width: 10%;
              `}
            >
              样品编号
            </th>
            <th
              className={css`
                width: 10%;
              `}
            >
              拟接人
            </th>
            <th
              className={css`
                width: 10%;
              `}
            >
              样品名称
            </th>

            <th
              className={css`
                width: 12%;
              `}
            >
              工程名称
            </th>
            <th
              className={css`
                width: 11%;
              `}
            >
              操作
            </th>
          </tr>
        </thead>
        <caption>当前查询共{count}条</caption>
        <tbody>
          {sampleItems ? (
            sampleItems.map((item) => (
              <tr
                key={item.id}
                className={
                  item.status === 1 || item.status === 0
                    ? css`
                        color: black;
                      `
                    : css`
                        color: #0000FF;
                      `
                }
              >
                <td>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked.indexOf(item.id) !== -1}
                        onChange={handleToggle(item.id)}
                        value={item.id}
                        color="primary"
                      />
                    }
                    label={`${
                      item.trust.certificate === 0 ? "JHWT" : "JHSWT"
                    }-${
                      item.trust.year
                    }-${item.trust.trustnumber.toString().padStart(4, "0")}`}
                  />
                </td>
                <td  className={
                  item.status > 2
                    ? css`
                    background-color: #4CAF50; /* Green */
                    border: none;
                    color: white;
                    padding: 15px 32px;
                    text-align: center;
                    text-decoration: none;
                    display: inline-block;
                    font-size: 16px;
                    margin:auto;
                      `
                    : css`

                      `
                }>{statusflags?.find((c) => c.id === item.status)?.name}</td>
                <td>{userItems?.find((c) => c.id === item.user_id)?.realname}</td>
                <td>{item.receivepersondate}</td>
                <td>{item.trust.trustperson}</td>
                <td>{`JHYP-${item.samplecode}-${
                  item.year
                }-${item.samplenumber.toString().padStart(4, "0")}`}</td>
                <td>{usermapItems?.find((c) => c.stdcode === item.samplecode)?.name}{"[拟]"}</td>
                <td>
                  {item.samplenickname?.length > 12
                    ? item.samplenickname?.substring(0, 12) + "..."
                    : item.samplenickname}
                </td>
                <td>
                  {item.trust.projectname?.length > 12
                    ? item.trust.projectname?.substring(0, 12) + "..."
                    : item.trust.projectname}
                </td>
                <td><button onClick={()=>{
                  setCurrentSample(item);
                  openTask();
                }}>任务详情</button></td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7}>没有数据哦!!</td>
            </tr>
          )}
        </tbody>
      </table>

      <Pagination
        pageSize={pageSize}
        totalRecords={count}
        currentPage={currentPage}
        onPageChange={paginate}
        defaultPageNeighbours={2}
      />
      <span>{loading && "正在加载!!,请等待"}</span>
      <span>{msg}</span>

      {showDialogUpdate && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogUpdate}
          onDismiss={closeUpdate}
        >
          <p>批量发放报告</p>
          <SendReportForm
            checked={checked}
            sampleItems={sampleItems}
            changeUi={changeUiReport}
            isEdite={false}
          />
          <hr />
          <button onClick={closeUpdate}>取消</button>
        </Dialog>
      )}
      {showDialogSendTask && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogSendTask}
          onDismiss={closeSendTask}
        >
          <p>批量发放任务</p>
          <SendTask
            checkedid={checked}
            sampleItems={sampleItems}
            usermapItems = {usermapItems}
            changeUi={changeUiRw}
          />
          <hr />
          <button onClick={closeSendTask}>取消</button>
        </Dialog>
      )}

      {showDialogTask && (
        <Dialog
          aria-label="modal"
          isOpen={showDialogTask}
          onDismiss={closeTask}
        >
          <h1>任务信息</h1>
          <TaskDetail sample={currentSample} />
          <hr />
          <button onClick={closeTask}>关闭</button>
        </Dialog>
      )}

      {showDialogCancelTask && (
        <AlertDialog onDismiss={closeCancelTask} leastDestructiveRef={cancelRef}>
          <AlertDialogLabel style={{ textAlign: "center", color: "red" }}>
            请再次确认是否取消任务?
          </AlertDialogLabel>
          <AlertDialogDescription>

          </AlertDialogDescription>
          <div className="alert-buttons">
            <button className="btn btn-danger" onClick={cancelTask}>
              是, 取消
            </button>
            <button
              style={{ marginLeft: "20px" }}
              ref={cancelRef}
              onClick={closeCancelTask}
            >
              取消, 我不愿意
            </button>
          </div>
        </AlertDialog>
      )}
    </div>
  );
}

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}
